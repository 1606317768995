// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.ourStoryContainer {
  @include container();
  @include mobile {
    min-height: 90rem;
  }
  @include mobileLandscape {
    min-height: 80rem;
  }
}
// CONTENT
.ourStoryContentContainer {
  @include content();
}
// TITLE
.ourStoryTitle {
  @include title();
}
// TEXT
.ourStoryTextLarge {
  @include textLarge();
  @include mobile {
    margin: 1rem 0rem;
  }
}
.ourStoryTextMedium {
  @include textMedium();
  @include mobile {
    margin: 1rem 0rem;
  }
}
.ourStoryTextSmall {
  @include textSmall();
  font-family: $fifthFont;
  @include mobile {
    margin: 1rem 0rem;
    max-width: 90vw;
  }
}
// DATA
.ourStoryDataContainer {
  @include space(9rem, 50%, relative);
  @include flex(row, center, flex-start);
  z-index: 10;
  @include mobile {
    @include space(auto, 100%);
    @include flex(row, center, space-evenly, wrap);
  }
  @include mobileLandscape {
    margin-top: 2rem;
    @include space(auto, 100%);
    @include flex(row, center, space-evenly);
  }
}
.ourStoryDataItemContainer {
  @include space(100%, auto);
  @include flex(column, center, flex-end, wrap);
  margin-right: 10rem;
  @include mobile {
    height: 9rem;
  }
  @include mobileLandscape {
    margin-right: 0rem;
  }
}
.ourStoryDataItemImage {
  max-height: 9rem;
  margin-right: 1.5rem;
  @include mobile {
    max-height: 7rem;
  }
  @include mobileLandscape {
    max-height: 8rem;
    margin-right: 0rem;
  }
}
.ourStoryDataItemValue {
  @include font($lightBlue, $fourthFont, 3.6rem);
  letter-spacing: 0.054rem;
  pointer-events: none;
  @include mobile {
    font-size: 3rem;
  }
  @include mobileLandscape {
    font-size: 3rem;
  }
}
.ourStoryDataItemKey {
  @include font($white, $secondFont, 1.4rem);
  letter-spacing: 0.07rem;
  margin-top: 1rem;
  pointer-events: none;
  text-transform: uppercase;
}
// BUTTON
.ourStoryButton {
  @include button();
}
