// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.headerContainer {
  @include space($headerHeight, 100%, fixed, 0rem);
  @include flex(row, center, flex-start);
  background: $blackHeader;
  padding-left: 1.5rem;
  transition: 0.3s ease;
  z-index: 20;
}
// TITLE
.headerTitleButton {
  @include space(2.6rem, 22rem);
  background: url("../../../assets/images/text/title.svg");
  background-size: cover;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
// PAGES
.headerPagesContainer {
  @include space(auto, auto, absolute, none, none, none, 1.5rem);
  @include flex(row, center, space-evenly);
  @include mobile {
    @include space(100%, 100%);
    @include flex(column, center, space-evenly);
  }
  @include mobileLandscape {
    @include space(100%, 100%);
    flex-wrap: wrap;
  }
}
.headerPageButton {
  @include font($white, $secondFont, 1.4rem);
  margin: 0 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  @include mobile {
    font-size: 2.4rem;
  }
  @include mobileLandscape {
    margin: 0;
    width: calc(100% / 5);
    font-size: 2rem;
  }
  &:hover {
    color: darken($white, 20);
  }
}
.headerMembersOnlyButton {
  @include button();
  height: 4rem;
  margin: 0rem 1.5rem;
  @include mobile {
    @include space(6rem, 22rem);
    font-size: 2.4rem;
  }
}
// MAP BUTTON
.headerMapButton {
  @include space(4.5rem, 4.5rem);
  @include flex(row, center, center);
  @include font($white, $firstFont, 2.4rem);
  background: $blue;
  border-radius: 50%;
  pointer-events: all;
  transition: 0.2s ease;
  cursor: pointer;
  @include mobile {
    @include space(5rem, 5rem);
    font-size: 3rem;
  }
  &:hover {
    background: $white;
    .headerMapIcon {
      color: $black;
    }
  }
}
// HAMBURGER BUTTON
.headerHamburgerButton {
  @include space(4rem, 4rem, absolute, none, none, none, 1.5rem);
  @include flex(row, center, center);
  @include font($white, $firstFont, 3rem);
  cursor: pointer;
}
// MENU
.headerMenuContainer {
  @include space(
    calc(#{$vh} - #{$headerHeight}),
    100vw,
    fixed,
    none,
    0rem,
    0rem
  );
  background: rgba($black, 0.9);
  z-index: 50;
}
