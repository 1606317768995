// FONT FAMILIES
@font-face {
  font-family: "Kaneda";
  src: url("https://use.typekit.net/af/a1fd38/00000000000000007735b5f0/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a1fd38/00000000000000007735b5f0/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a1fd38/00000000000000007735b5f0/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: Inter;
  src: url("../assets/fonts/Inter.otf") format("opentype");
}
@font-face {
  font-family: Coanda;
  src: url("../assets/fonts/Coanda.otf") format("opentype");
}
@font-face {
  font-family: SpaceGrotesk;
  src: url("../assets/fonts/SpaceGrotesk.otf") format("opentype");
}
@font-face {
  font-family: AvantGarde;
  src: url("../assets/fonts/AvantGarde.otf") format("opentype");
}
$firstFont: "Kaneda", sans-serif;
$secondFont: "Inter", sans-serif;
$thirdFont: "Coanda", monospace;
$fourthFont: "SpaceGrotesk", sans-serif;
$fifthFont: "AvantGarde", sans-serif;
// COLORS
$white: #f7f7f7;
$whiteFade: rgba(255, 255, 255, 0.2);
$whiteFadeBright: rgba(255, 255, 255, 0.8);
$lightWhite: #d1d1d1;
$black: #0f0f0f;
$blackHeader: rgba(15, 15, 15, 0.9);
$blackFade: rgba(0, 0, 0, 0.5);
$blackFadeBright: rgba(0, 0, 0, 0.8);
$blackFadeLight: rgba(0, 0, 0, 0.3);
$lightBlack: #2f2f2f;
$yellow: #f8ee07;
$blue: #2735ec;
$lightBlue: #27ceec;
$lightBlueFade: rgba(39, 206, 236, 0.8);
// BOX SHADOWS
$boxShadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.3);
$boxShadowAcitve: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
$boxShadowShoe: rgba(0, 0, 0, 0.3) 0px 19px 38px,
  rgba(0, 0, 0, 0.22) 0px 15px 12px;
// OTHER
$vh: calc(var(--vh, 1vh) * 100);
$vw: calc(var(--vw, 1vw) * 100);
$headerHeight: 7rem;
$marginSpacing: 3.5rem 0rem;
$marginSpacingMobile: 2rem 0rem;
// BREAK POINTS
$mobileBreakpoint: 700px;
$tabletBreakpoint: 1050px;
