// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.teamContainer {
  @include container();
  background: url("../../../assets/images/backgrounds/background-team.jpeg");
  background-size: cover;
  background-position: center;
  z-index: 15;
  @include mobile {
    @include flex(column, center, center);
    min-height: 85rem;
  }
  @include mobileLandscape {
    padding-top: 3rem;
    min-height: 70rem;
  }
}
// CONTENT
.teamContentContainer {
  @include content();
}
// TITLE
.teamTitle {
  @include title();
}
// TEXT
.teamTextLarge {
  @include textLarge();
}
.teamTextSmall {
  @include textSmall();
  font-family: $fifthFont;
}
// MEMBERS
.teamMembersContainer {
  @include space(35rem, calc(100% - 10rem), relative);
  @include flex(column, center, center, wrap);
  @include border(0.2rem, $blue);
  background: $blackFadeLight;
  border-radius: 1.5rem;
  overflow-x: scroll;
  z-index: 10;
  @include mobile {
    @include space(30rem, 90%);
  }
  @include mobileLandscape {
    @include space(30rem, 90%);
    margin-top: 2rem;
  }
}
.teamMember {
  @include space(100%, 30rem, relative);
  @include flex(column, center, space-evenly);
  @include mobile {
    @include space(100%, 20rem);
  }
  @include mobileLandscape {
    @include space(100%, 20rem);
  }
}
.teamMemberName {
  @include textSmall();
  margin: 0rem;
  white-space: nowrap;
}
.teamMemberDescription {
  @include textSmall();
  @include font(darken($white, 30), $secondFont, 1.8rem);
  margin: 0rem;
  text-align: center;
  width: 70%;
  @include mobile {
    font-size: 1.6rem;
    margin: 0rem;
    width: 80%;
  }
  @include mobileLandscape {
    font-size: 1.6rem;
    margin: 0rem;
    width: 80%;
  }
}
.teamMemberImage,
.teamMemberImageLarge {
  @include space(15rem, 15rem);
  background-size: cover !important;
  background-position: center !important;
  border-radius: 5rem;
  background-color: skyblue !important;
  @include mobile {
    @include space(10rem, 10rem);
  }
  @include mobileLandscape {
    @include space(10rem, 10rem);
  }
}
.teamMemberImageLarge {
  background-size: 20rem 20rem !important;
  @include mobile {
    background-size: 15rem 15rem !important;
  }
  @include mobileLandscape {
    background-size: 15rem 15rem !important;
  }
}
.teamMemberLink {
  @include textSmall();
  @include font(darken($white, 30), $secondFont, 1.8rem);
  margin: 0rem;
  pointer-events: all;
  text-decoration: none;
  cursor: pointer;
  @include mobile {
    font-size: 1.6rem;
  }
  @include mobileLandscape {
    font-size: 1.6rem;
  }
  &:hover {
    color: $white;
  }
}
