// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.faqContainer {
  @include container();
  background: $blackFade;
  @include mobile {
    height: auto;
    min-height: 80rem;
  }
  @include mobileLandscape {
    height: auto;
    padding-top: 3rem;
    min-height: 65rem;
  }
}
// CONTENT
.faqContentContainer {
  @include content();
  padding-bottom: 20rem;
}
// TITLE
.faqTitle {
  @include title();
}
// TEXT LARGE
.faqTextLarge {
  @include textLarge();
}
// QUESTIONS
.faqQuestionsContainer {
  @include space(auto, 80%);
  @include flex(column, flex-start, flex-start);
  @include mobileLandscape {
    width: 90%;
  }
}
.faqQuestion {
  @include space(auto, 60%, relative);
  @include flex(column, flex-start, space-evenly);
  margin: 1rem 0rem;
  cursor: pointer;
  @include mobile {
    width: 100%;
  }
  &:hover {
    .faqQuestionText {
      color: darken($white, 20);
    }
  }
}
.faqQuestionText {
  @include textMedium();
  margin: 0rem;
  @include mobile {
    margin: 0rem;
  }
  @include mobileLandscape {
    margin: 0rem;
  }
}
.faqQuestionIcon {
  @include space(auto, auto, absolute, 0.5rem, none, none, 1rem);
  @include font($blue, $firstFont, 3rem);
  @include mobile {
    top: 0rem;
    right: 0rem;
  }
  @include mobileLandscape {
    top: 0rem;
    right: 0rem;
  }
}
.faqQuestionAnswerText {
  @include textSmall();
  font-family: $fifthFont;
  font-size: 2rem;
  margin: 0rem;
  margin-top: 1rem;
  @include mobile {
    font-size: 1.8rem;
  }
  @include mobileLandscape {
    font-size: 1.8rem;
  }
}
// DEEDS
.faqDeedsContainer {
  @include space(20rem, 100%, absolute, none, 0rem, 0rem);
  @include flex(column, center, flex-start, wrap);
  background: $black;
  overflow-x: scroll;
  z-index: 10;
}
.faqDeedImage {
  @include space(100%, auto);
}
