// IMPORTS
@import "./Variables";
// SPACE
@mixin space(
  $height,
  $width,
  $position: static,
  $top: none,
  $bottom: none,
  $left: none,
  $right: none,
  $translate: false
) {
  height: $height;
  width: $width;
  position: $position;
  @if $position == absolute or $position == fixed {
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
    @if $translate {
      transform: translate(-50%, -50%);
    }
  }
}
// FLEX
@mixin flex($direction, $align, $justify, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}
// FONT
@mixin font($color, $family, $size) {
  color: $color;
  font-family: $family;
  font-size: $size;
}
// BORDER
@mixin border($width, $color, $side: false) {
  @if $side {
    @if $side == top {
      border-top: $width solid $color;
    } @else if $side == bottom {
      border-bottom: $width solid $color;
    } @else if $side == left {
      border-left: $width solid $color;
    } @else if $side == right {
      border-right: $width solid $color;
    }
  } @else {
    border: $width solid $color;
  }
}
// CONTAINER
@mixin container {
  @include space(calc(#{$vh} - #{$headerHeight}), 100vw, relative);
  @include flex(row, center, center);
  @include mobileLandscape {
    align-items: flex-start;
  }
}
// CONTENT
@mixin content {
  @include space(auto, 100%);
  max-width: 180rem;
  padding-left: 10rem;
  @include mobile {
    padding-left: 0rem;
    @include flex(column, center, center);
  }
  @include mobileLandscape() {
    padding-left: 0rem;
    @include flex(column, center, center);
  }
}
// TITLE
@mixin title {
  @include space(5rem, 20rem);
  @include flex(row, center, center);
  @include font($white, $secondFont, 1.6rem);
  background-color: $whiteFade;
  backdrop-filter: blur(0.5rem);
  border-radius: 0.5rem;
  letter-spacing: 0.07rem;
  pointer-events: none;
  text-transform: uppercase;
}
// TEXT LARGE
@mixin textLarge {
  @include font($white, $firstFont, 8.8rem);
  letter-spacing: 0.132rem;
  margin: $marginSpacing;
  pointer-events: none;
  position: relative;
  text-transform: uppercase;
  z-index: 10;
  @include mobile {
    font-size: 5.5rem;
    text-align: center;
    margin: $marginSpacingMobile;
  }
  @include mobileLandscape() {
    font-size: 5.5rem;
    text-align: center;
    margin: $marginSpacingMobile;
  }
}
// TEXT MEDIUM
@mixin textMedium {
  @include font($white, $firstFont, 3.2rem);
  letter-spacing: 0.048rem;
  margin: $marginSpacing;
  pointer-events: none;
  position: relative;
  text-transform: uppercase;
  z-index: 10;
  @include mobile {
    font-size: 3rem;
    margin: $marginSpacingMobile;
  }
  @include mobileLandscape() {
    font-size: 3rem;
    margin: $marginSpacingMobile;
  }
}
// TEXT SMALL
@mixin textSmall {
  @include font($white, $thirdFont, 2.4rem);
  letter-spacing: 0.07rem;
  margin: $marginSpacing;
  max-width: 50vw;
  pointer-events: none;
  position: relative;
  z-index: 10;
  @include mobile {
    font-size: 2rem;
    max-width: 80vw;
    margin: $marginSpacingMobile;
  }
  @include mobileLandscape {
    font-size: 2rem;
    max-width: 80vw;
    margin: $marginSpacingMobile;
  }
}
// BUTTON
@mixin button() {
  @include space(5rem, 17rem);
  @include flex(row, center, center);
  @include font($white, $secondFont, 1.6rem);
  background: $blue;
  border-radius: 0.3rem;
  margin: $marginSpacing;
  margin-top: 5rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease;
  cursor: pointer;
  @include mobile {
    margin: $marginSpacingMobile;
  }
  @include mobileLandscape() {
    margin: $marginSpacingMobile;
  }
  &:hover {
    background: $white;
    color: $black;
  }
}
// ICON BUTTON
@mixin iconButton {
  @include font($white, $firstFont, 4rem);
  cursor: pointer;
  &:hover {
    color: darken($white, 30);
  }
  @include mobile {
    font-size: 3rem;
  }
}
// INSTRUCTIONS
@mixin instructions($withAnimation) {
  @include space(9rem, 50rem);
  @include flex(row, center, center);
  @include border(0.2rem, $blue);
  @include textMedium();
  background: $blackFadeLight;
  border-radius: 1.5rem;
  margin-top: 4rem;
  padding: 0rem 1rem;
  @if $withAnimation {
    animation: pulseAnimation 2s infinite;
  }
  @include mobile {
    @include space(9rem, 30rem);
  }
}
// PULSE ANIMATION
@keyframes pulseAnimation {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

// MEDIA QUERIES
@mixin mobile {
  @media screen and (max-width: $mobileBreakpoint) {
    @content;
  }
}
@mixin mobilePortrait {
  @media screen and (max-width: $mobileBreakpoint) and (orientation: "portrait") {
    @content;
  }
}
@mixin mobileLandscape {
  @media screen and (max-height: $mobileBreakpoint) and (orientation: "landscape") {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $tabletBreakpoint) {
    @content;
  }
}
@mixin tabletPortrait {
  @media screen and (max-width: $tabletBreakpoint) and (orientation: "portrait") {
    @content;
  }
}
@mixin tabletLandscape {
  @media screen and (max-height: $tabletBreakpoint) and(orientation: "landscape") {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: $tabletBreakpoint) {
    @content;
  }
}
