// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.footerContainer {
  @include space(3.5rem, 100%, fixed, none, 1rem);
  @include flex(row, center, center);
  pointer-events: none;
  z-index: 40;
}
// INSTRUCTIONS
.footerInstructionsContainer {
  @include flex(row, center, center);
}
.footerInstructionsText {
  @include font($white, $secondFont, 1.4rem);
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}
.footerInstructionsIcon {
  @include font($white, $secondFont, 3rem);
  margin: 0 1.5rem;
}
// SOCIAL MEDIA BUTTONS
.footerSocialMediaContainer {
  @include space(auto, auto, absolute, none, none, none, 1.5rem);
  @include flex(row, center, center);
}
.footerSocialMediaButton {
  @include space(3.5rem, 3.5rem);
  @include flex(row, center, center);
  background: $white;
  border-radius: 0.5rem;
  margin: 0.5rem;
  pointer-events: all;
  transition: 0.2s ease;
  cursor: pointer;
  @include mobile {
    @include space(3rem, 3rem);
  }
  &:hover {
    background: $black;
    .footerSocialMediaIcon {
      color: $white;
    }
  }
}
.footerSocialMediaIcon {
  @include font($black, $firstFont, 2.2rem);
  @include mobile {
    font-size: 1.8rem;
  }
}
