// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.loadingContainer {
  @include space(200rem, 100vw, relative);
  @include flex(row, center, center);
  background: $black;
  z-index: 30;
}
// PORTAL
.loadingPortal {
  @include space(100vh, 100vw, fixed, none, 0rem);
  background: url("../../../assets/images/backgrounds/background-loading.png");
  background-size: cover;
  background-position: center;
  pointer-events: none;
  z-index: 10;
}
// OBELISK
.loadingObelisk {
  @include space(150rem, auto, absolute, 17rem);
}
// TEXT
.loadingText {
  @include textLarge();
  @include space(auto, auto, fixed, calc(50vh - 4.4rem));
  margin: 0rem;
}
// TITLE
.loadingTitleContainer {
  @include space(auto, 120rem, absolute, none, 2rem);
  @include flex(column, center, center);
}
.loadingTitle {
  @include textLarge();
  font-size: 10rem;
  letter-spacing: 0.3rem;
  margin-bottom: 2.5rem;
}
.loadingTitleImage {
  @include space(auto, 120rem);
}
// TUCK
.loadingTuck {
  @include space(7rem, 100%);
  background: $black;
  z-index: 7;
}
