// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.roadmapContainer {
  @include container();
  background: url("../../../assets/images/backgrounds/background-roadmap.jpeg");
  background-size: cover;
  z-index: 15;
  @include mobile {
    @include flex(column, center, center);
    min-height: 80rem;
  }
  @include mobileLandscape {
    min-height: 75rem;
  }
}
// CONTENT
.roadmapContentContainer {
  @include content();
  height: 100%;
  @include flex(column, flex-start, center, wrap);
}
// TITLE
.roadMapTitle {
  @include title();
}
// TEXT
.roadmapTextLarge {
  @include textLarge();
}
.roadmapTextSmall {
  @include textSmall();
  width: 40%;
  font-family: $fifthFont;
  @include mobile {
    width: 100%;
  }
  @include mobileLandscape {
    width: 100%;
  }
}
// INSTRUCTIONS
.roadmapInstructions {
  @include instructions(false);
}
// VIDEO
.roadmapVideo {
  @include space(auto, 50%);
  @include mobile {
    width: 98%;
    margin-top: 2rem;
    position: static;
  }
  @include mobileLandscape {
    width: 60%;
    margin-top: 2rem;
    position: static;
  }
}
