// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// BACKGROUND
.obeliskBackground {
  @include space(
    calc(#{$vh} - #{$headerHeight}),
    100vw,
    fixed,
    none,
    0rem,
    none,
    0rem
  );
  @include flex(row, center, center);
  pointer-events: none;
  z-index: 10;
  @include mobile {
    z-index: 20;
  }
}
// CONTAINER
.obeliskContainer {
  @include space(100%, 80rem, absolute, none, 0rem, none, 0rem);
  @include flex(row, flex-end, center);
  @include mobile {
    @include space(#{$vh}, 100%, absolute);
    background: $blackFadeBright;
  }
}
// CLOSE BUTTON
.obeliskCloseButton {
  @include space(4rem, 4rem, absolute, 9rem, none, none, 1rem);
  @include flex(row, center, center);
  pointer-events: all;
  cursor: pointer;
  @include mobile {
    top: 1.5rem;
    right: 1.5rem;
  }
}
.obeliskCloseButtonIcon {
  @include iconButton();
}
// TEXT
.obeliskText {
  @include instructions(false);
  @include mobile {
    @include space(9rem, 30rem, absolute, 2rem);
    margin: 0rem;
    text-align: center;
    background: $blackFade;
  }
}
// IMAGE
.obeliskImage {
  @include space(98%, auto);
  @include mobile {
    @include space(auto, 100%, absolute, none, 5rem);
  }
}
// ORB BUTTON
.obeliskOrbButton {
  @include space(6rem, 6rem, absolute);
  cursor: pointer;
  z-index: 10;
  pointer-events: all;
  @include mobile {
    @include space(4rem, 4rem, absolute);
  }
}
.obeliskOrbImage {
  @include space(100%, 100%);
}
// CAROUSEL
.obeliskCarouselContainer {
  @include space(100%, 100%);
  @include flex(column, center, flex-start, wrap);
  background: $blackFade;
  pointer-events: all;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  z-index: 10;
  @include mobile {
    background: $blackFadeBright;
  }
}
// CONTROLS
.obeliskCarouselControlsContainer {
  @include space(5rem, 100%, absolute, 2rem);
  @include flex(row, center, space-evenly);
  padding: 0rem 20%;
  z-index: 15;
}
.obeliskCarouselLeftArrowButton,
.obeliskCarouselRightArrowButton {
  @include iconButton();
  @include space(auto, auto, absolute, none, none, 1.5rem);
  @include mobile {
    @include space(auto, auto, absolute, 12rem, none, 1.5rem);
  }
}
.obeliskCarouselRightArrowButton {
  left: unset;
  right: 1.5rem;
}
.obeliskCarouselCloseButton {
  @include iconButton();
  @include space(auto, auto, absolute);
  @include mobile {
    @include space(4rem, 4rem, absolute, -0.5rem, none, none, 1.5rem);
    @include flex(row, center, center);
  }
}
// ITEM
.obeliskCarouselItem {
  @include space(100%, 25%, relative);
  @include flex(column, center, center);
  @include mobile {
    @include space(100%, 100%);
  }
}
.obeliskCarouselItemImage,
.obeliskCarouselItemImageLarge,
.obeliskCarouselImageMoveUp {
  max-height: 40%;
  max-width: 60%;
  @include mobile {
    max-height: 60%;
    max-width: 60%;
  }
}
.obeliskCarouselItemImageLarge {
  max-height: 60%;
  max-width: 40%;
  margin-bottom: 4rem;
  @include mobile {
    top: 6rem;
    max-height: 45%;
  }
}
.obeliskCarouselImageMoveUp {
  max-width: 90%;
  margin-bottom: 4rem;
  @include mobile {
    max-width: 90%;
  }
}
.obeliskCarouselItemTitle {
  @include textLarge();
  font-size: 6.4rem;
  margin: 2rem 0rem;
  text-align: center;
}
.obeliskCarouselItemText {
  @include textSmall();
  text-align: center;
  max-width: 65vw;
  @include mobile {
    max-width: 95vw;
    padding: unset;
    font-size: 1.8rem;
  }
}
