// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.landingContainer {
  @include container();
  @include mobile {
    min-height: 70rem;
  }
  @include mobileLandscape {
    min-height: 45rem;
  }
}
// CONTENT
.landingContentContainer {
  @include content();
}
// TEXT
.landingTextLarge {
  @include textLarge();
}
.landingTextSmall {
  @include textSmall();
  font-family: $fifthFont;
}
// BUTTON
.landingButton {
  @include button();
}
