// IMPORTS
@import "./Mixins";
@import "./Variables";
@import "./modules/components/Footer";
@import "./modules/components/Header";
@import "./modules/components/Loading";
@import "./modules/components/Obelisk";
@import "./modules/components/Video";
@import "./modules/pages/FAQ";
@import "./modules/pages/Landing";
@import "./modules/pages/MembersOnly";
@import "./modules/pages/OurStory";
@import "./modules/pages/Roadmap";
@import "./modules/pages/Team";
@import "./modules/pages/TheCharter";
// REGULARIZATION
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  resize: none;
  background: none;
}
// SCROLL BAR
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar {
  height: 0rem;
  width: 0rem;
}
*::-webkit-scrollbar-thumb {
  background: transparent;
}
// STYLES
:root {
  font-size: 62.5%;
}
body {
  background: $black;
  overflow: hidden;
}
// CONTAINER
.appContainer {
  @include space(100vh, 100vw, relative);
  @include flex(row, center, center, wrap);
  background: url("../assets/images/backgrounds/background-main.jpeg");
  background-size: cover;
  background-position-y: -5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 7rem;
  scroll-behavior: smooth;
  @include mobile {
    padding-bottom: 7rem;
  }
}
// SCROLL BAR
.appScrollBar {
  @include space(8rem, 0.7rem, fixed, none, none, 0rem);
  background: $blue;
  z-index: 20;
}
