// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.theCharterContainer {
  @include container();
  min-height: 50rem;
  @include mobile {
    min-height: 65rem;
  }
  @include mobileLandscape {
    min-height: 50rem;
  }
}
// CONTENT
.theCharterContentContainer {
  @include content();
}
// TITLE
.theCharterTitle {
  @include title();
}
// TEXT
.theCharterTextLarge {
  @include textLarge();
  @include mobile {
    width: 95%;
  }
}
.theCharterTextSmall {
  @include textSmall();
  font-family: $fifthFont;
}
// INSTRUCTIONS
.theCharterInstructions {
  @include instructions(true);
  @include mobile {
    pointer-events: all;
  }
  @include mobileLandscape {
    pointer-events: all;
  }
}
