// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.videoContainer {
  @include space(100%, 100%, relative);
  @include flex(row, center, center);
  border-radius: 2.5rem;
  overflow: hidden;
  @include mobile {
    overflow: visible;
  }
}
// PLAY BUTTON
.videoPlayButton {
  @include space(5rem, 5rem, absolute, none, 0rem, 0rem);
  @include flex(row, center, center);
  @include font($white, $firstFont, 2rem);
  background: $blackFadeBright;
  border-top-right-radius: 2.5rem;
  z-index: 5;
  cursor: pointer;
}
// MUTE BUTTON
.videoMuteButton {
  @include space(5rem, 5rem, absolute, none, 0rem, none, 0rem);
  @include flex(row, center, center);
  @include font($white, $firstFont, 2rem);
  background: $blackFadeBright;
  border-top-left-radius: 2.5rem;
  z-index: 5;
  cursor: pointer;
}
