// IMPORTS
@import "../../Mixins";
@import "../../Variables";
// CONTAINER
.membersOnlyContainer {
  @include container();
  @include flex(row, center, center);
  background: url("../../../assets/images/backgrounds/background-membersOnly.jpeg");
  background-position: center;
  z-index: 15;
  @include mobile {
    background-position-y: -100rem;
    background-size: cover;
    min-height: 75rem;
  }
  @include mobileLandscape {
    align-items: center;
    background-size: cover;
    background-position-y: -56rem;
    min-height: 50rem;
  }
}
// BACKGROUND IMAGES
.membersOnlyBackgroundImagesContainer {
  @include space(45rem, 120%, absolute);
  @include flex(row, center, center, wrap);
  @include mobile {
    height: 24rem;
  }
  @include mobileLandscape {
    display: none;
    height: 24rem;
  }
}
.membersOnlyBackgroundImage {
  @include space(15rem, 100%);
  background-size: auto 15rem;
  @include mobile {
    @include space(8rem, 100%);
    background-size: auto 8rem;
  }
  @include mobileLandscape {
    @include space(8rem, 100%);
    background-size: auto 8rem;
  }
}
// JOIN BUTTON
.membersOnlyJoinButton {
  @include button();
  @include space(6rem, 29rem, absolute);
  z-index: 5;
}
// TITLE IMAGE
.membersOnlyTitleImage {
  @include space(auto, 46rem, absolute, none, 10rem);
  @include mobile {
    @include space(auto, 30rem, absolute, none, 10rem);
  }
  @include mobileLandscape {
    @include space(auto, 40rem, absolute, none, 8rem);
  }
}
